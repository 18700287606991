<template>
    <div>
        <page-title :heading="this.$t('settings.lang_nav_settings_roomplaner')"
                    :subheading="this.$t('erp.lang_roomPlanerEditRoom')"
                    :icon=icon
                    show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <room-edit-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import RoomEditComponent from "../../../../components/settings/cashier/TablePlaner/RoomEditComponent";
    export default {
        name: "TablePlanerEdit",
        components:{
            RoomEditComponent,
            PageTitle
        },
        data(){
           return{
               icon: 'pe-7s-monitor icon-gradient bg-tempting-azure',
           }
        },
    }
</script>

<style scoped>

</style>